import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { FRONTEND_BASE_URL } from "./constants/global";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Auth0Provider
    domain="dev-0vrgy1n5.us.auth0.com"
    clientId="lWnpfYK5yJmC6TBetZhsWCzbpp9gXiW6"
    useRefreshTokens
    cacheLocation="localstorage"
    audience="https://api.acttstudy.org/"
    redirectUri={`${FRONTEND_BASE_URL}/welcome`}
  >
    <App />
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
