import * as tf from "@tensorflow/tfjs";
import { renderBoxes } from "./renderBox";

/**
 * Preprocess image / frame before forwarded into the model
 * @param {HTMLVideoElement|HTMLImageElement} source
 * @param {Number} modelWidth
 * @param {Number} modelHeight
 * @returns input tensor, xRatio and yRatio
 */
const preprocess = async (src, modelWidth, modelHeight) => {
  const image = new Image();
  image.src = src;
  return await new Promise((resolve) => {
    image.onload = function () {
      const input = tf.tidy(() => {
        const img = tf.browser.fromPixels(image);

        // padding image to square => [n, m] to [n, n], n > m
        const imgPadded = img;

        return tf.image
          .resizeBilinear(imgPadded, [modelWidth, modelHeight]) // resize frame
          .div(255.0) // normalize
          .expandDims(0); // add batch
      });
      resolve(input);
    };
  });
};

/**
 * Function to detect image.
 * @param {string} imageSrc image src
 * @param {tf.GraphModel} model loaded YOLOv5 tensorflow.js model
 * @param {Number} classThreshold class threshold
 * @param {HTMLCanvasElement} canvasRef canvas reference
 */
export const detectImage = async (
  imageSrc,
  model,
  classThreshold,
  canvasRef
) => {
  const [modelWidth, modelHeight] = model.inputShape.slice(1, 3); // get model width and height
  tf.engine().startScope(); // start scoping tf engine
  const input = await preprocess(imageSrc, modelWidth, modelHeight);
  console.log({ input });
  let noEggs = 0;
  await model.net.executeAsync(input).then((res) => {
    const [boxes, scores] = res.slice(0, 3);
    const boxes_data = boxes.dataSync();
    const scores_data = scores.dataSync();

    renderBoxes(canvasRef, classThreshold, boxes_data, scores_data); // render boxes
    for (let j = 0; j < scores_data.length; j++) {
      if (scores_data[j] > 0) {
        noEggs = noEggs + 1;
      }
    }
    tf.dispose(res); // clear memory
  });

  tf.engine().endScope(); // end of scoping
  return noEggs;
};
