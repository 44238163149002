import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IconButton, Popover, Typography, Button } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { BASE_URL } from "../constants/global";
import { Link } from "react-router-dom";

function ProfileButton() {
  const { isAuthenticated, getAccessTokenSilently, logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;
  const [userData, setUserData] = useState({
    affiliation: "",
    email: user?.email || "",
    name: user?.name || "",
    subId: user?.sub || "",
    givenName: user?.givenName || "",
  });
  useEffect(() => {
    if (isAuthenticated && userData.subId) {
      const getUserProfile = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const response = await fetch(`${BASE_URL}/v1/user/profile`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          // Check if the response status is 200
          if (response.ok) {
            // Check if the response body is not empty to determine if the user profile exists
            const userProfile = await response.json();
            if (Object.keys(userProfile).length !== 0) {
              // User profile exists, update the state
              setUserData(userProfile);
            } else {
              // User profile not found, handle accordingly
              console.log("User profile not found");
            }
          } else {
            // Handle other status codes if needed
            console.error("Failed to fetch user profile:", response.status);
          }
        } catch (error) {
          // Handle network or other errors
          console.error("Error fetching user profile:", error);
        }
      };

      getUserProfile();
    }
  }, [isAuthenticated, userData.subId, getAccessTokenSilently]);

  const handleProfileClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <>
      {isAuthenticated && (
        <>
          <IconButton
            onClick={handleProfileClick}
            aria-describedby={id}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ padding: 20 }}>
              <Typography variant="h6" gutterBottom>
                {userData.name}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Affiliation: {userData.affiliation ?? "not set"}
              </Typography>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                component={Link}
                to="/profile"
                sx={{ marginBottom: 1 }}
              >
                View Profile
              </Button>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={handleLogout}
                sx={{ marginBottom: 1 }}
              >
                Logout
              </Button>
            </div>
          </Popover>
        </>
      )}
    </>
  );
}

export default ProfileButton;
