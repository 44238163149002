import React from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DataObjectIcon from "@mui/icons-material/DataObject";
import EggIcon from "@mui/icons-material/Egg";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface EggCountDisplayProps {
  computedEggCount: number | null;
  predictions: any[];
  onViewJsonClick: () => void;
}

interface CustomLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    name: string;
    value: number;
  }>;
}

const EggCountDisplay: React.FC<EggCountDisplayProps> = ({
  computedEggCount,
  predictions,
  onViewJsonClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (computedEggCount == null) {
    return null;
  }

  const getColor = (count: number) => {
    if (count === 0) return "#808080"; // Gray for no eggs
    return "#f44336"; // Red if eggs
  };

  const processData = (predictions: any[]) => {
    if (predictions.length === 0) {
      return [{ name: "No eggs detected", color: "#808080", count: 1 }];
    }

    const ranges = [
      { name: "0.0 - 0.25", color: "#FF0000", count: 0 },
      { name: "0.25 - 0.5", color: "#FFA500", count: 0 },
      { name: "0.5+", color: "#00FF00", count: 0 },
    ];
    predictions.forEach((prediction) => {
      if (prediction.confidence < 0.25) {
        ranges[0].count++;
      } else if (prediction.confidence < 0.5) {
        ranges[1].count++;
      } else {
        ranges[2].count++;
      }
    });
    return ranges.filter((range) => range.count > 0);
  };

  const data = processData(predictions);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: CustomLabelProps) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: { xs: 2, sm: 4 },
        borderRadius: 2,
        backgroundColor: "#f5f5f5",
        boxShadow: 3,
        width: "auto",
        maxWidth: "calc(100% - 32px)",
        marginTop: 3,
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "hidden",
        paddingBottom: { xs: 4, sm: 6 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "stretch",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xs: "100%", sm: "45%" },
            marginBottom: { xs: 3, sm: 0 },
          }}
        >
          <Typography variant="h4" gutterBottom align="center">
            Egg Count
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: isMobile ? 300 : 400,
              minHeight: 300,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: isMobile ? 200 : 270,
                height: isMobile ? 200 : 270,
                borderRadius: "50%",
                backgroundColor: getColor(computedEggCount),
                color: "white",
                marginBottom: 2,
              }}
            >
              <Typography variant="h2">{computedEggCount}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EggIcon sx={{ mr: 1, color: getColor(computedEggCount) }} />
              <Typography variant="body1">
                {computedEggCount === 0
                  ? "No Eggs Detected"
                  : computedEggCount === 1
                  ? "Egg Detected"
                  : "Eggs Detected"}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: { xs: "100%", sm: "45%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom align="center">
            Confidence Distribution
          </Typography>
          {computedEggCount === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: isMobile ? 300 : 400,
                minHeight: 300,
              }}
            >
              <Typography variant="body1" align="center">
                No eggs detected in the image.
              </Typography>
            </Box>
          ) : (
            <ResponsiveContainer
              width="100%"
              height={isMobile ? 300 : 400}
              minHeight={300}
            >
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={isMobile ? 100 : 130}
                  fill="#8884d8"
                  dataKey="count"
                  label={renderCustomizedLabel}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign="bottom"
                  height={40}
                  iconSize={12}
                  wrapperStyle={{ fontSize: "14px", marginTop: "10px" }}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Box>

      <Button
        variant="outlined"
        color="secondary"
        onClick={onViewJsonClick}
        startIcon={<DataObjectIcon />}
        sx={{ marginTop: 3 }}
      >
        View Metadata
      </Button>
    </Box>
  );
};

export default EggCountDisplay;
