import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import ButtonAppBar from "../components/ButtonAppBar";
import { ProfileForm } from "../components/ProfileForm";

export const Welcome: React.FC = () => {
  useEffect(() => {
    document.title = "ACTT Study";
  }, []);

  useAuth0();
  return (
    <div className="App">
      <ButtonAppBar />
      <div style={{ padding: 20 }}>
        <img src="/logo.png" alt="ACTT Logo" style={{ maxWidth: 200 }} />
        <h2>Welcome to ACTT Study</h2>
        <ProfileForm />
      </div>
    </div>
  );
};

export default Welcome;
