import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Button, Typography, Box, Grid, Paper } from "@mui/material";
import ButtonAppBar from "../components/ButtonAppBar";
import SponsorImages from "../components/SponsorImages";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import BugReportIcon from "@mui/icons-material/BugReport";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ScienceIcon from "@mui/icons-material/Science";

export const Home: React.FC = () => {
  useEffect(() => {
    document.title =
      "ACTT Study - Aedes Control through Technology-driven Trapping";
  }, []);

  const { isAuthenticated } = useAuth0();

  return (
    <Box className="App">
      <ButtonAppBar />
      <Box sx={{ padding: 4, maxWidth: 1000, margin: "0 auto" }}>
        <img
          src="/logo.png"
          alt="ACTT Logo"
          style={{ maxWidth: 200, marginBottom: 20 }}
        />

        <Typography variant="h4" gutterBottom>
          ACTT Study: Aedes Control through Technology-driven Trapping
        </Typography>

        <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
          <p>
            Mosquito-borne diseases are a global concern that impact public
            health, tourism, and natural disaster recovery efforts. With no
            preventative vaccines or effective therapeutic option available for
            most mosquito-borne diseases, vector control efforts are the only
            combative option. These efforts are often limited by financial
            constraint and insecticide resistance concern. The ACTT study aims
            to integrate cutting-edge mosquito collection traps, innovative new
            methods for assessing mosquito risk, and novel remote sensing and
            machine learning workflows to guide local vector operations and
            direct abatement efforts in a cost-efficient manner.
          </p>
        </Paper>

        <Box sx={{ my: 4 }}>
          {isAuthenticated ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/images"
                  startIcon={<VisibilityIcon />}
                >
                  View Your Records
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  component={Link}
                  to="/add"
                  startIcon={<BugReportIcon />}
                >
                  Count Eggs (OpenCV)
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  component={Link}
                  to="/yolo"
                  startIcon={<BugReportIcon />}
                >
                  Count Eggs (YOLO)
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  component={Link}
                  to="/yolocloud"
                  startIcon={<BugReportIcon />}
                >
                  Count Eggs (Cloud YOLO)
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/playground"
              size="large"
              startIcon={<ScienceIcon />}
            >
              Try Our Algorithm
            </Button>
          )}
        </Box>

        <Typography
          variant="h5"
          gutterBottom
          sx={{
            mt: 4,
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
        >
          Supported by
        </Typography>
      </Box>

      <SponsorImages />
    </Box>
  );
};

export default Home;
