import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import React, { useEffect, useState } from "react";
import {
  Alert,
  IconButton,
  InputAdornment,
  Rating,
  Stack,
} from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import ButtonAppBar from "./ButtonAppBar";
import { Controller, useForm } from "react-hook-form";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../constants/global";
import { UploadImage } from "./UploadImage";
import { useGeolocated } from "react-geolocated";
import LocationOn from "@mui/icons-material/LocationOn";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getValue } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import packageJson from "../../package.json";
import "../pages/AedesForms.css";

type FormElem = React.FormEvent<HTMLFormElement>;

export const ImageForm: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "ACTT | Add (opencv)";
  }, []);
  const [error, setError] = useState(false);
  const { isAuthenticated, user } = useAuth0();
  const {
    register,
    setValue,
    handleSubmit,
    control,
    getValues,

    formState: { errors },
  } = useForm({
    defaultValues: {
      authorId: 0,
      authorIdentity: "",
      fileName: "",
      address: "",
      siteId: "",
      descriptorId: undefined,
      weekId: undefined,
      uniqueImageId: "",
      sampleName: "",
      setUpDate: dayjs(),
      collectionDate: dayjs(),
      pictureDate: dayjs(),
      manualEggCount: undefined,
      species: "",
      hatchingDate: dayjs(),
      origin: "lab",
      type: "blank",
      heightPhoto: undefined,
      stand: "",
      anglePhoto: undefined,
      device: "",
      resolution: "",
      lightCondition: "dim",
      photoCondition: "indoor",
      paperType: "seed paper",
      paperColor: "white",
      paperCondition: "wet",
      waterType: "tap",
      trapType: "ovicup",
      deployTime: undefined,
      computedEggCount: 0,
      rating: null,
      note: "",
      clientInfo: "",
      timeStamp: null,
    },
  });

  const [locale, setLocale] = React.useState<string>("en");

  const [setUpDate, setSetUpDate] = React.useState<Dayjs | null>(dayjs());
  // useState<Date | null>(new Date());

  const [collectionDate, setCollectionDate] = React.useState<Dayjs | null>(
    dayjs()
  );

  const [pictureDate, setPictureDate] = React.useState<Dayjs | null>(dayjs());

  const [hatchingDate, setHatchingDate] = React.useState<Dayjs | null>(dayjs());

  const [fileName, setFileName] = useState<string>("");

  const [computedEggCount, setComputedEggCount] = useState<number>(0);

  const [imagesSubmitted, setImagesSubmitted] = useState<boolean>(false);

  const [additionalOptions, setAdditionalOptions] = useState<boolean>(false);

  const [rating, setRating] = useState<number | null>(null);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  const populateLocation = (event: any) => {
    setValue("address", coords?.latitude + ", " + coords?.longitude);
  };
  const onSubmit = (event: any) => {
    // event.preventDefault();
    // console.log("updatedValues" + getValues());
    // console.log(String(setUpDate));
    //Post applciation JSON from form values along with the context token. Set the context ids and then post.
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': `Bearer ${context.token}`
        },
      };
      setValue("authorIdentity", user?.email ? user?.email : "no_email");
      setValue("setUpDate", setUpDate ? setUpDate : dayjs());
      setValue("collectionDate", collectionDate ? collectionDate : dayjs());
      setValue("pictureDate", pictureDate ? pictureDate : dayjs());
      setValue("hatchingDate", hatchingDate ? hatchingDate : dayjs());
      setValue("authorId", 23);
      setValue("fileName", fileName);
      setValue("computedEggCount", computedEggCount);
      setValue("clientInfo", "Web v" + packageJson.version + " OpenCV");

      const body = JSON.stringify(getValues());
      // console.log("Values" + body);
      const res = axios
        .post(`${BASE_URL}/v1/image/`, body, config)
        .then((res) => {
          // console.log("Resulting data" + res.data);
          if (res.status == 200) {
            console.log("Status is " + res.status);
            // props.history.push('/images/')
          }
          navigate("/images");
        })
        .catch((e) => {
          setError(true);
          console.error(e + ":\n" + (e as AxiosError)?.response?.data);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const origins = [
    {
      value: "field",
    },
    {
      value: "lab",
    },
  ];
  const types = [
    {
      value: "blank",
    },
    {
      value: "positive",
    },
    {
      value: "negative",
    },
  ];
  const lightConditions = [
    {
      value: "dim",
    },
    {
      value: "light",
    },
  ];
  const photoConditions = [
    {
      value: "indoor",
    },
    {
      value: "outdoor",
    },
  ];
  const paperTypes = [
    {
      value: "seed paper",
    },
    {
      value: "whatman paper",
    },
  ];
  const paperConditions = [
    {
      value: "wet",
    },
    {
      value: "dry",
    },
  ];
  const waterTypes = [
    {
      value: "tap",
    },
    {
      value: "other",
    },
  ];
  const trapTypes = [
    {
      value: "ovicup",
    },
    {
      value: "white remakin",
    },
  ];
  return (
    <div className="App">
      <ButtonAppBar />
      {/* <Button
        onClick={() => {
          console.log(`${fileName} ' ' ${computedEggCount}`);
        }}
      >
        Check States
      </Button> */}
      <h1>Image Information</h1>

      {/* <h2>Help us learn more about the sample</h2> */}
      <UploadImage
        setFileName={(fileName) => setFileName(fileName)}
        setComputedEggCount={(computedEggCount) =>
          setComputedEggCount(computedEggCount)
        }
      />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      ></Box>
      {/* <form onSubmit={async () => { handleSubmit(onSubmit);  setTimeout(() => {
            navigate("/images");
         }, 500);}}> */}
      <form className="textLeft" onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={3}
          paddingLeft={10}
          paddingRight={10}
          paddingBottom={10}
        >
          <Controller
            control={control}
            name="siteId"
            defaultValue=""
            render={({ field }) => (
              <TextField
                id="siteId"
                {...register("siteId", { required: "Site Id is required" })}
                label="Site Id"
                variant="outlined"
                required
                error={!!errors.siteId}
                helperText={errors.siteId?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="descriptorId"
            render={({ field }) => (
              <TextField
                id="descriptorId"
                label="Descriptor Id"
                type="number"
                variant="outlined"
                {...register("descriptorId", {
                  required: "Descriptor Id is required",
                  min: {
                    value: 1,
                    message: "Descriptor Id must be a positive value",
                  },
                })}
                required
                error={!!errors.descriptorId}
                helperText={errors.descriptorId?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="weekId"
            render={({ field }) => (
              <TextField
                id="weekId"
                label="Week Id"
                type="number"
                variant="outlined"
                {...register("weekId")}
              />
            )}
          />
          <Controller
            control={control}
            name="sampleName"
            defaultValue=""
            render={({ field }) => (
              <TextField
                id="sampleName"
                label="Sample Name"
                variant="outlined"
                {...field}
                {...register("sampleName")}
              />
            )}
          />
          <Controller
            control={control}
            name="address"
            defaultValue=""
            render={({ field }) => (
              <TextField
                id="address"
                label="Address"
                variant="outlined"
                {...field}
                {...register("address", { required: true })}
                required
                error={!!errors.address}
                helperText={errors.address?.message}
                InputProps={{
                  endAdornment:
                    isGeolocationEnabled && isGeolocationAvailable ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Populate Location"
                          onClick={populateLocation}
                        >
                          <LocationOn />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      ""
                    ),
                }}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name="setUpDate"
              render={({ field }) => (
                <DatePicker
                  label="Setup Date"
                  // inputFormat="MM/dd/yyyy"
                  value={setUpDate}
                  onChange={(newValue) => setSetUpDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                // <DesktopDatePicker
                //   label="Setup Date"
                //   inputFormat="MM/dd/yyyy"
                //   value={setUpDate}
                //   onChange={(date) => date && setSetUpDate(date)}
                //   renderInput={(params) => <TextField {...params} />}
                // />
              )}
            />
            <Controller
              control={control}
              name="collectionDate"
              render={({ field }) => (
                <DesktopDatePicker
                  label="Collection Date"
                  value={collectionDate}
                  onChange={(date) => date && setCollectionDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
            <Controller
              control={control}
              name="pictureDate"
              render={({ field }) => (
                <DesktopDatePicker
                  label="Picture Date"
                  value={pictureDate}
                  onChange={(date) => date && setPictureDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
          {/* setUpDate
     collectionDate
     pictureDate */}

          {/* Hatching Date */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name="hatchingDate"
              render={({ field }) => (
                <DesktopDatePicker
                  label="Hatching Date"
                  value={hatchingDate}
                  onChange={(date) => date && setHatchingDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>

          <Controller
            control={control}
            name="deployTime"
            render={({ field }) => (
              <TextField
                id="deployTime"
                label="Deploy Time (days)"
                type="number"
                variant="outlined"
                {...register("deployTime", {
                  required: "Deploy Time is required",
                  min: {
                    value: 1,
                    message: "Deploy Time Id must be a positive value",
                  },
                })}
                required
                error={!!errors.deployTime}
                helperText={errors.deployTime?.message}
              />
            )}
          />

          {additionalOptions ? (
            <>
              <Controller
                control={control}
                name="manualEggCount"
                render={({ field }) => (
                  <TextField
                    id="manualEggCount"
                    label="Manual Egg Count"
                    type="number"
                    variant="outlined"
                    defaultValue={getValues("manualEggCount")}
                    {...register("manualEggCount", {
                      min: {
                        value: 0,
                        message:
                          "Manual Egg Count Time Id must be a positive value",
                      },
                    })}
                  />
                )}
              />
              <Controller
                control={control}
                name="species"
                render={({ field }) => (
                  <TextField
                    id="species"
                    label="Species"
                    variant="outlined"
                    defaultValue={getValues("species")}
                    {...register("species")}
                  />
                )}
              />
              <Controller
                control={control}
                name="origin"
                render={({ field }) => (
                  <TextField
                    id="origin"
                    select
                    label="Origin"
                    placeholder="Origin"
                    defaultValue={getValues("origin")}
                    // value={origin}
                    {...register("origin", { required: "Origin is required" })}
                    required
                    error={!!errors.origin}
                    helperText={errors.origin?.message}
                    // onChange={handleChange}
                  >
                    {origins.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="lightCondition"
                render={({ field }) => (
                  <TextField
                    id="lightCondition"
                    select
                    label="Light Condition"
                    defaultValue={getValues("lightCondition")}
                    {...register("lightCondition", {
                      required: "Light Condition is required",
                    })}
                    required
                    error={!!errors.lightCondition}
                    helperText={errors.lightCondition?.message}
                    // onChange={handleChange}
                  >
                    {lightConditions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="photoCondition"
                render={({ field }) => (
                  <TextField
                    id="photoCondition"
                    select
                    label="Photo Condition"
                    defaultValue={getValues("photoCondition")}
                    {...register("photoCondition", {
                      required: "Photo Condition is required",
                    })}
                    required
                    error={!!errors.photoCondition}
                    helperText={errors.photoCondition?.message}
                    // onChange={handleChange}
                  >
                    {photoConditions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="paperType"
                render={({ field }) => (
                  <TextField
                    id="paperType"
                    select
                    label="Paper Type"
                    defaultValue={getValues("paperType")}
                    {...register("paperType", {
                      required: "Paper Type is required",
                    })}
                    required
                    error={!!errors.paperType}
                    helperText={errors.paperType?.message}
                    // onChange={handleChange}
                  >
                    {paperTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="paperColor"
                render={({ field }) => (
                  <TextField
                    id="paperColor"
                    label="Paper Color"
                    variant="outlined"
                    defaultValue={getValues("paperColor")}
                    {...register("paperColor", {
                      required: "Paper Color is required",
                    })}
                    required
                    error={!!errors.paperColor}
                    helperText={errors.paperColor?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="paperCondition"
                render={({ field }) => (
                  <TextField
                    id="paperCondition"
                    select
                    label="Paper Condition"
                    defaultValue={getValues("paperCondition")}
                    {...register("paperCondition", {
                      required: "Paper Condition is required",
                    })}
                    required
                    error={!!errors.paperCondition}
                    helperText={errors.paperCondition?.message}

                    // onChange={handleChange}
                  >
                    {paperConditions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="trapType"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    id="trapType"
                    select
                    label="Trap Type"
                    defaultValue={getValues("trapType")}
                    {...register("trapType", {
                      required: "Trap Type is required",
                    })}
                    required
                    error={!!errors.trapType}
                    helperText={errors.trapType?.message}
                    // onChange={handleChange}
                  >
                    {trapTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="waterType"
                render={({ field }) => (
                  <TextField
                    id="waterType"
                    select
                    label="Water Type"
                    defaultValue={getValues("waterType")}
                    {...register("waterType", {
                      required: "Water Type is required",
                    })}
                    required
                    error={!!errors.waterType}
                    helperText={errors.waterType?.message}
                    // onChange={handleChange}
                  >
                    {waterTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="type"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    id="type"
                    select
                    label="Type"
                    defaultValue={getValues("type")}
                    {...register("type", { required: "Type is required" })}
                    required
                    error={!!errors.type}
                    helperText={errors.type?.message}
                    // onChange={handleChange}
                  >
                    {types.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="heightPhoto"
                render={({ field }) => (
                  <TextField
                    id="heightPhoto"
                    label="Photo Height"
                    type="number"
                    variant="outlined"
                    defaultValue={getValues("heightPhoto")}
                    {...register("heightPhoto", {
                      min: {
                        value: 0,
                        message: "Photo height must be a positive value",
                      },
                    })}
                  />
                )}
              />
              <Controller
                control={control}
                name="stand"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    id="stand"
                    label="Stand"
                    variant="outlined"
                    defaultValue={getValues("stand")}
                    {...register("stand")}
                  />
                )}
              />
              <Controller
                control={control}
                name="anglePhoto"
                render={({ field }) => (
                  <TextField
                    id="anglePhoto"
                    label="Photo Angle"
                    type="number"
                    variant="outlined"
                    defaultValue={getValues("anglePhoto")}
                    {...register("anglePhoto", {
                      min: {
                        value: 0,
                        message: "The photo angle must be 0-360 degrees",
                      },
                      max: {
                        value: 360,
                        message: "The photo angle must be 0-360 degrees",
                      },
                    })}
                  />
                )}
              />
              <Controller
                control={control}
                name="device"
                render={({ field }) => (
                  <TextField
                    id="device"
                    label="Device"
                    variant="outlined"
                    defaultValue={getValues("device")}
                    {...register("device")}
                  />
                )}
              />
              <Controller
                control={control}
                name="resolution"
                render={({ field }) => (
                  <TextField
                    id="resolution"
                    label="Resolution"
                    variant="outlined"
                    defaultValue={getValues("resolution")}
                    {...register("resolution")}
                  />
                )}
              />
              <Controller
                control={control}
                name="note"
                render={({ field }) => (
                  <TextField
                    id="note"
                    label="Note"
                    variant="outlined"
                    multiline
                    rows={4}
                    {...register("note")}
                  />
                )}
              />
              <Button onClick={() => setAdditionalOptions(false)}>
                Hide Options
              </Button>{" "}
            </>
          ) : (
            <Button onClick={() => setAdditionalOptions(true)}>
              More Options
            </Button>
          )}
          {computedEggCount !== null ? (
            <Button type="submit" variant="outlined" color="success">
              Submit
            </Button>
          ) : (
            <>
              <Button type="submit" disabled variant="outlined" color="success">
                Submit
              </Button>
              <Alert severity="warning">
                You must first select and upload your image
              </Alert>
            </>
          )}
        </Stack>
      </form>
    </div>
  );
};

export default ImageForm;
