import React, { useRef } from "react";
import { Modal, Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

interface ZoomModalProps {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
  label?: string;
}

const ZoomModal: React.FC<ZoomModalProps> = ({
  open,
  onClose,
  imageUrl,
  label,
}) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const fitToScreen = () => {
    if (transformComponentRef.current) {
      const { centerView, zoomToElement } = transformComponentRef.current;
      centerView(1, 0);
      zoomToElement("zoomable-image");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "90vw",
          height: "90vh",
          maxWidth: "1200px",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          overflow: "hidden",
        }}
      >
        {label && (
          <Typography
            variant="h6"
            sx={{
              position: "absolute",
              top: 16,
              left: 16,
              zIndex: 9999,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              padding: "5px 10px",
              borderRadius: "4px",
            }}
          >
            {label}
          </Typography>
        )}
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          centerOnInit={true}
          ref={transformComponentRef}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <Box
                sx={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  zIndex: 9999,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "4px",
                  padding: "4px",
                }}
              >
                <Tooltip title="Zoom In">
                  <IconButton onClick={() => zoomIn()} size="small">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Zoom Out">
                  <IconButton onClick={() => zoomOut()} size="small">
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reset">
                  <IconButton onClick={() => resetTransform()} size="small">
                    <RestartAltIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Fit to Screen">
                  <IconButton onClick={fitToScreen} size="small">
                    <FullscreenIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close">
                  <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <TransformComponent
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  id="zoomable-image"
                  src={imageUrl}
                  alt="Modal View"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Box>
    </Modal>
  );
};

export default ZoomModal;
