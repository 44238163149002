import * as React from "react";
import { ImageList, ImageListItem, Box, useMediaQuery, useTheme } from "@mui/material";

export default function SponsorImages() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const getGridCols = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    return 4;
  };

  return (
    <ImageList 
      sx={{ 
        padding: { xs: 2, sm: 3, md: 4 },
        justifyContent: "center",
        gap: { xs: 24, sm: 32, md: 40 },
      }} 
      cols={getGridCols()} 
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} sx={{ height: "auto !important" }}>
          <Box
            component="a"
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: { xs: 90, sm: 120, md: 140 },
              padding: 2,
              borderRadius: 2,
              transition: "all 0.3s ease-in-out",
              "&:hover, &:focus": {
                boxShadow: 3,
                transform: "translateY(-5px)",
              },
              "&:focus-visible": {
                outline: `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "2px",
              },
            }}
          >
            <img
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
              style={{
                objectFit: "contain",
                maxHeight: "100%",
                maxWidth: "100%",
                transition: "transform 0.3s ease-in-out",
              }}
            />
          </Box>
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: "/asph_logo.png",
    title: "University of South Carolina",
    url: "https://sc.edu/study/colleges_schools/public_health/index.php",
  },
  {
    img: "./thumbnail_solid-blue-baylor-logo.png",
    title: "Baylor University",
    url: "https://www.bcm.edu/",
  },
  {
    img: "./fiu-white-logo.png",
    title: "Florida International University",
    url: "https://www.fiu.edu/",
  },
  {
    img: "./nih-logo-color.png",
    title: "National Institute of Health",
    url: "https://www.nih.gov/",
  },
];