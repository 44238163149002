import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Link } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import ProfileButton from "./ProfileButton";

export default function ButtonAppBar() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "#73000a" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="/logo.png"
              alt="ACTT Logo"
              style={{ height: 40, marginRight: 10 }}
            />
            <Typography variant="h6" component="div">
              <Link href="/" underline="none" style={{ color: "white" }}>
                ACTT Study
              </Link>
            </Typography>
          </Box>
          <Box>{isAuthenticated ? <ProfileButton /> : <LoginButton />}</Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
