import "./App.css";
import ImageForm from "./components/ImageForm";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
// import ImageUpload from "./components/imageUpload";
import Home from "./pages/Home";
// import ConceptThree from "./components/ConceptThree";
// import AedesTable from "./components/AedesTable";
import ViewAedes from "./pages/ViewAede";
import AedesTable from "./components/AedesTable";
// import UploadImage from "./components/UploadImage";
import UploadImage from "./components/UploadImage";
import Profile from "./pages/Profile";
import ViewTable from "./pages/ViewTable";
import { Error } from './components/Error';
import { Loading } from './components/Loading';
// import { Users } from './components/Users';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import YoloAdd from "./pages/YoloAdd";
import YoloImage from "./components/YoloImage";
import YoloImageNoSubmit from "./components/YoloNoSubmit";
import { useEffect } from "react";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { ViewCamera } from "./pages/ViewCamera";
import ViewCameraV2 from "./pages/ViewCameraV2";
import { MobileCameraView } from "./components/MobileCameraView";
import { MobileCamera } from "./pages/MobileCamera";
import Welcome from "./pages/Welcome";
import AuthenticationGuard from "./components/authentication-guard";
import EditAede from "./pages/EditAede";
import YoloCloudAdd from "./pages/YoloCloudAdd";
// import YoloCameraAdd from "./pages/YoloCameraAdd";
// const ProtectedUsers = withAuthenticationRequired(Users);

function App() {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    if(isAuthenticated){
    loginWithRedirect()
    }
    console.log(JSON.stringify(error))
    // return <Error message={error.message} />;
  }
  return (

    <BrowserRouter>
      {/* {error && <Error message={error.message} />} */}
      <Routes>

        <Route path="/cam" element={<AuthenticationGuard component={MobileCamera} />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/add" element={<AuthenticationGuard component={ImageForm} />} />
        <Route path="/images" element={<AuthenticationGuard component={ViewTable} />} />
        <Route path="/image/:id" element={<AuthenticationGuard component={ViewAedes} />} />
        <Route path="/edit/:id" element={<AuthenticationGuard component={EditAede} />} />
        <Route path="/profile" element={<AuthenticationGuard component={Profile} />} />
        <Route path="/yolo" element={<AuthenticationGuard component={YoloAdd} />} />
        <Route path="/yolocloud" element={<AuthenticationGuard component={YoloCloudAdd} />} />
        <Route path="/playground" element={<YoloImageNoSubmit />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>

  )
}
export default App;
