import React, { useState, useCallback } from "react";
import CloudButtonHandler from "./CloudButtonHandler";
import "../styles/yolo.css";
import { v4 as uuidv4 } from "uuid";
import { Box, Button, Modal, Slider, Typography } from "@mui/material";
import { BASE_URL } from "../constants/global";
import axios from "axios";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { useAuth0 } from "@auth0/auth0-react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { Crop } from "react-image-crop";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ZoomModal from "./ZoomModal";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EggCountDisplay from "./EggCountDisplay";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
type fileNameProps = {
  setFileName: (arg: string) => void;
  setComputedEggCount: (arg: number) => void;
  setImageSubmitted: (arg: boolean) => void;
  setAnalyzedResult: (arg: any) => void;
};

export const CloudYolo: React.FC<fileNameProps> = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [imagesSubmitted, setImagesSubmitted] = useState<boolean>(false);
  const [imageAnalyzed, setImageAnalyzed] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState(false);
  const [computedEggCount, setComputedEggCount] = useState<number>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [jsonModalOpen, setJsonModalOpen] = useState<boolean>(false);
  const [activeImage, setActiveImage] = useState<string | null>(null);
  const [confidenceThreshold, setConfidenceThreshold] = useState<number>(0.25);
  const [crop, setCrop] = useState<Crop>();
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [activeImageLabel, setActiveImageLabel] = useState<string | null>(null);
  const [predictions, setPredictions] = useState<any>(null);
  const [isReanalyzing, setIsReanalyzing] = useState<boolean>(false);

  const handleModalOpen = (image: string, label?: string) => {
    setActiveImage(image);
    if (label) {
      setActiveImageLabel(label);
    }
    setModalOpen(true);
  };

  const handleModalClose = () => setModalOpen(false);

  const handleJsonModalOpen = () => setJsonModalOpen(true);

  const onImageLoad = useCallback((img: HTMLImageElement) => {
    const loadImage = () => {
      const aspect = 16 / 9;
      const width = img.width;
      const height = width / aspect;
      setCrop({
        unit: "%",
        width: 100,
        height: (100 * height) / width,
        x: 0,
        y: 0,
      });
    };

    if (img.complete) {
      loadImage();
    } else {
      img.onload = loadImage;
    }
  }, []);
  const getCroppedImg = useCallback(
    (
      image: HTMLImageElement | null,
      crop: Crop
    ): Promise<string | undefined> => {
      return new Promise((resolve) => {
        if (!image) {
          console.error("Image not found");
          resolve(undefined);
          return;
        }

        if (!image.complete) {
          image.onload = () => {
            resolve(cropImage(image, crop));
          };
          image.onerror = () => {
            console.error("Error loading image");
            resolve(undefined);
          };
        } else {
          resolve(cropImage(image, crop));
        }
      });
    },
    []
  );

  const cropImage = (
    image: HTMLImageElement,
    crop: Crop
  ): Promise<string | undefined> => {
    return new Promise<string | undefined>((resolve) => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      // Use the original image dimensions for the canvas
      canvas.width = crop.width! * scaleX;
      canvas.height = crop.height! * scaleY;

      const ctx = canvas.getContext("2d");

      ctx!.drawImage(
        image,
        crop.x! * scaleX,
        crop.y! * scaleY,
        crop.width! * scaleX,
        crop.height! * scaleY,
        0,
        0,
        crop.width! * scaleX,
        crop.height! * scaleY
      );

      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            resolve(undefined);
            return;
          }
          const croppedImageUrl = window.URL.createObjectURL(blob);
          resolve(croppedImageUrl);
        },
        "image/jpeg",
        1
      );
    });
  };

  const handleCropComplete = useCallback(
    async (crop: Crop) => {
      if (imageSrc && crop.width && crop.height) {
        const image = document.querySelector(
          ".ReactCrop__child-wrapper img"
        ) as HTMLImageElement | null;
        if (image) {
          const croppedImage = await getCroppedImg(image, crop);
          if (croppedImage) {
            setCroppedImageUrl(croppedImage);
          }
        } else {
          console.error("Image element not found");
        }
      }
    },
    [imageSrc, getCroppedImg]
  );

  const analyzeImage = async (imageUrl: string) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const localUUID = uuidv4();
      const formData = new FormData();
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const fileName =
        blob.type === "image/jpeg"
          ? `${localUUID}.jpg`
          : `${localUUID}.${blob.type.split("/")[1]}`;
      formData.append("file", blob, fileName);
      formData.append("model_name", "aedes_yolov5n");
      formData.append("confidence_threshold", confidenceThreshold.toString());

      const apiResponse = await axios.post(
        `${BASE_URL}/files/forward`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      const eggCount = apiResponse.data.egg_count;
      const predictions = apiResponse.data.predictions;
      setComputedEggCount(eggCount);
      props.setComputedEggCount(eggCount);
      if (predictions) {
        setPredictions(predictions);
        props.setAnalyzedResult(predictions);
      }
      setImageBase64(apiResponse.data.image_base64);
      setImageAnalyzed(true);
    } catch (error) {
      console.error("Error during image analysis:", error);
      throw error;
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!croppedImageUrl) {
        throw new Error("Please crop the image before submitting");
      }
      await analyzeImage(croppedImageUrl);
    } catch (error) {
      console.error("Error during image prediction:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReanalyze = async () => {
    try {
      setIsReanalyzing(true);
      setImageAnalyzed(false);
      setLoading(true);
      setConfidenceThreshold(0.25);
    } catch (error) {
      console.error("Error during image reanalysis:", error);
    } finally {
      setLoading(false);
      setIsReanalyzing(false);
    }
  };

  const handleUpload = async () => {
    setIsUploading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const localUUID = uuidv4();
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      };

      // Upload the analyzed image
      const analyzedBlob = await imageToBlobFromBase64(imageBase64!);
      const analyzedFormData = new FormData();
      analyzedFormData.append(
        "file",
        analyzedBlob,
        `${localUUID}_analyzed.jpg`
      );
      await axios.post(`${BASE_URL}/files`, analyzedFormData, config);

      // Upload the original image
      const img = new Image();
      img.src = croppedImageUrl!;
      const originalBlob = await imageToBlob(img);
      const originalFormData = new FormData();
      originalFormData.append("file", originalBlob, `${localUUID}.jpg`);
      await axios.post(`${BASE_URL}/files`, originalFormData, config);

      // Update the parent component with the file name
      props.setFileName(`${localUUID}`);
      setImagesSubmitted(true);
    } catch (error) {
      console.error("Error during image upload:", error);
    } finally {
      setIsUploading(false);
      setImagesSubmitted(true);
      props.setImageSubmitted(true);
    }
  };

  async function imageToBlobFromBase64(base64: string): Promise<Blob> {
    const response = await fetch(`data:image/jpeg;base64,${base64}`);
    const blob = await response.blob();
    return blob;
  }

  async function imageToBlob(image: HTMLImageElement): Promise<Blob> {
    if (!image.complete) {
      await new Promise((resolve) => {
        image.onload = resolve;
      });
    }
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context?.drawImage(image, 0, 0, image.width, image.height);
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob!);
      }, "image/jpeg");
    });
  }

  const handleClear = () => {
    setImageSrc(null);
    setImageBase64(null);
    setComputedEggCount(0);
    setImagesSubmitted(false);
    setImageAnalyzed(false);
    setCroppedImageUrl(null);
    setPredictions(null);
  };

  return (
    <div>
      <div className="Aedes-view">
        <div className="header">
          <h1>Create a new record</h1>
          <h2>YOLO Cloud Model</h2>
        </div>
        <div className="centered-container">
          <div className="image-container">
            {!imageAnalyzed && imageSrc && (
              <div className="image-wrapper">
                <h3>Original Image</h3>
                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={handleCropComplete}
                >
                  <img
                    src={imageSrc}
                    alt="Original Image"
                    onLoad={(e) => onImageLoad(e.currentTarget)}
                  />
                </ReactCrop>
                {loading && (
                  <div className="loader-overlay centered">
                    <div className="loader-spinner"></div>
                    <h3>Processing...</h3>
                  </div>
                )}
              </div>
            )}
            {imageBase64 && (
              <div className="result-display-cards">
                {croppedImageUrl && (
                  <div className="image-wrapper">
                    <h3>Cropped Image</h3>
                    <img
                      className="yolo-image"
                      src={croppedImageUrl}
                      alt="Cropped Image"
                      onClick={() =>
                        handleModalOpen(croppedImageUrl, "Cropped Image")
                      }
                    />
                  </div>
                )}
                {imageBase64 && (
                  <div className="image-wrapper">
                    <h3>Analyzed Image</h3>
                    <img
                      className="yolo-image"
                      src={`data:image/jpeg;base64,${imageBase64}`}
                      alt="Analyzed Image"
                      onClick={() =>
                        handleModalOpen(
                          `data:image/jpeg;base64,${imageBase64}`,
                          "Analyzed Image"
                        )
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <CloudButtonHandler
            imageSrcState={[imageSrc, setImageSrc]}
            setComputedEggCount={setComputedEggCount}
            imagesSubmitted={imagesSubmitted}
            clearImage={handleClear}
          />
          {imageAnalyzed && !imagesSubmitted && !isUploading && (
            <>
              <Button
                color="warning"
                variant="outlined"
                onClick={handleReanalyze}
                disabled={isReanalyzing}
                style={{ marginTop: "20px" }}
                startIcon={<RefreshIcon />}
              >
                {isReanalyzing ? "Reanalyzing..." : "Reanalyze"}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleUpload}
                style={{ marginTop: "20px" }}
                startIcon={<CloudUploadIcon />}
              >
                Upload Image with Bounding Boxes
              </Button>
            </>
          )}

          {(!imageAnalyzed || isReanalyzing) && !loading && imageSrc && (
            <div className="confidence-slider">
              <Typography id="confidence-slider" gutterBottom>
                Confidence Threshold
              </Typography>
              <Slider
                value={confidenceThreshold}
                onChange={(_, newValue) =>
                  setConfidenceThreshold(newValue as number)
                }
                aria-labelledby="confidence-slider"
                step={0.05}
                marks
                min={0}
                max={1}
                valueLabelDisplay="auto"
              />
            </div>
          )}

          {!imageAnalyzed && !loading && croppedImageUrl && (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleSubmit}
              style={{ margin: "10px" }}
              startIcon={<SearchIcon />}
            >
              Submit for Bounding Box Detection
            </Button>
          )}
          {imageAnalyzed && (
            <Box sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}>
              <EggCountDisplay
                computedEggCount={computedEggCount ?? null}
                onViewJsonClick={handleJsonModalOpen}
                predictions={predictions ?? []}
              />
            </Box>
          )}

          {isUploading && <CircularProgressWithLabel value={uploadProgress} />}
          {imagesSubmitted && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 3,
                padding: 2,
                backgroundColor: "#e8f5e9",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <CheckCircleIcon
                color="success"
                sx={{ fontSize: 40, marginBottom: 1 }}
              />
              <Typography variant="h5" color="success.main" gutterBottom>
                Image Submitted Successfully
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Your image has been uploaded and processed.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Please complete the form and submit.
              </Typography>
            </Box>
          )}
        </div>
      </div>

      <Modal
        open={jsonModalOpen}
        onClose={() => setJsonModalOpen(false)}
        aria-labelledby="json-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            id="json-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Analyzed Results
          </Typography>
          <Box sx={{ flexGrow: 1, overflow: "auto", mb: 2 }}>
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {JSON.stringify(predictions, null, 2)}
            </pre>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "auto",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(
                  JSON.stringify(predictions, null, 2)
                );
              }}
              startIcon={<ContentCopyIcon />}
            >
              Copy
            </Button>
            <Button
              variant="contained"
              component="a"
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(predictions, null, 2)
              )}`}
              download={`raw.json`}
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
      <ZoomModal
        open={modalOpen}
        onClose={handleModalClose}
        imageUrl={activeImage || ""}
        label={activeImageLabel || ""}
      />
    </div>
  );
};

export default CloudYolo;
