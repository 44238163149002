import { Button } from "@mui/material";
import { useRef, useState } from "react";
import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";
import heic2any from "heic2any";

interface CloudButtonHandlerProps {
  imageSrcState: [
    string | null,
    React.Dispatch<React.SetStateAction<string | null>>
  ];
  setComputedEggCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  imagesSubmitted: boolean;
  clearImage: () => void;
}

const CloudButtonHandler: React.FC<CloudButtonHandlerProps> = ({
  imageSrcState,
  setComputedEggCount,
  imagesSubmitted,
  clearImage,
}) => {
  const [imageSrc, setImageSrc] = imageSrcState;
  const inputImageRef = useRef<HTMLInputElement | null>(null);
  const [streaming, setStreaming] = useState<string | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleClear = () => {
    clearImage();
    setStreaming(null);
    if (inputImageRef.current) {
      inputImageRef.current.value = "";
    }
  };

  const convertHeicToJpeg = async (file: File): Promise<File> => {
    if (
      file.type === "image/heic" ||
      file.name.toLowerCase().endsWith(".heic")
    ) {
      setIsProcessing(true);
      try {
        const jpegBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8,
        });
        setIsProcessing(false);
        return new File(
          [jpegBlob as Blob],
          file.name.replace(/\.heic$/i, ".jpg"),
          { type: "image/jpeg" }
        );
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        setIsProcessing(false);
        throw error;
      }
    }
    return file;
  };

  const handleFile = async (file: File) => {
    try {
      const processedFile = await convertHeicToJpeg(file);
      const url = URL.createObjectURL(processedFile);
      setImageSrc(url);
      setStreaming("image");
    } catch (error) {
      console.error("Error processing file:", error);
      alert(
        "Error processing the image. Please try again with a different file."
      );
    }
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const file = e.dataTransfer.files?.[0];
    if (file) {
      await handleFile(file);
    }
  };

  return (
    <div
      className={`btn-container ${dragging ? "dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        border: dragging ? "2px dashed #aaa" : "2px solid transparent",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <input
        type="file"
        accept="image/*,.heic"
        style={{ display: "none" }}
        onChange={async (e) => {
          const file = e.target.files?.[0];
          if (!file) return;
          await handleFile(file);
        }}
        ref={inputImageRef}
      />

      {imagesSubmitted ? null : (
        <Button
          variant="outlined"
          color={streaming === "image" ? "error" : "primary"}
          onClick={() => {
            if (!streaming) {
              inputImageRef.current?.click();
            } else if (streaming === "image") {
              handleClear();
            } else {
              alert(
                `Can't handle more than 1 stream\nCurrently streaming: ${streaming}`
              );
            }
          }}
          startIcon={streaming === "image" ? <ClearIcon /> : <ImageIcon />}
          disabled={isProcessing}
        >
          {isProcessing
            ? "Processing..."
            : streaming === "image"
            ? "Clear"
            : "Select"}{" "}
          Image
        </Button>
      )}

      {!streaming && !dragging && (
        <p style={{ textAlign: "center", color: "#aaa", marginTop: "10px" }}>
          Drag and drop an image or click to select
        </p>
      )}
    </div>
  );
};

export default CloudButtonHandler;
