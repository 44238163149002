/**
 * Render prediction boxes
 * @param {HTMLCanvasElement} canvasRef canvas tag reference
 * @param {number} classThreshold class threshold
 * @param {Array} boxes_data boxes array
 * @param {Array} scores_data scores array
 */
export const renderBoxes = async (
  canvasRef,
  classThreshold,
  boxes_data,
  scores_data
) => {
  const ctx = canvasRef.getContext("2d");

  // font configs
  const font = `${Math.max(
    Math.round(Math.max(ctx.canvas.width, ctx.canvas.height) / 40),
    10
  )}px Arial`;
  ctx.font = font;
  ctx.textBaseline = "top";

  let totalEggs = 0;
  for (let i = 0; i < scores_data.length; ++i) {
    await new Promise((resolve) => {
      setTimeout(() => {
        // Perform some task asynchronously
        // filter based on class threshold
        if (scores_data[i] > classThreshold) {
          totalEggs = totalEggs + 1;

          const score = (scores_data[i] * 100).toFixed(1);

          let [x1, y1, x2, y2] = boxes_data.slice(i * 4, (i + 1) * 4);
          x1 *= canvasRef.width;
          x2 *= canvasRef.width;
          y1 *= canvasRef.height;
          y2 *= canvasRef.height;
          const width = x2 - x1;
          const height = y2 - y1;

          // draw border box.

          score > 30
            ? (ctx.strokeStyle = "#FF3838")
            : (ctx.strokeStyle = "#296D98");
          ctx.lineWidth = 1;
          ctx.strokeRect(x1, y1, width, height);

          // Draw the label background.

          score > 30
            ? (ctx.fillStyle = "#FF3838")
            : (ctx.fillStyle = "#296D98");

          const textWidth = ctx.measureText(score + "%").width;
          const textHeight = parseInt(font, 8); // base 10
          const yText = y1 - (textHeight + ctx.lineWidth);
          ctx.fillRect(
            x1 - 1,
            yText < 0 ? 0 : yText, // handle overflow label box
            textWidth + ctx.lineWidth,
            textHeight + ctx.lineWidth
          );

          // Draw labels
          ctx.fillStyle = "#ffffff";

          ctx.fillText(score + "%", x1 - 1, yText < 0 ? 0 : yText);
        }

        resolve();
      }, 0);
    });
  }
};

// ??????
// class Colors {
//   // ultralytics color palette https://ultralytics.com/
//   constructor() {
//     this.palette = [
//       "#FF3838",
//       "#FF9D97",
//       "#FF701F",
//     ];
//     this.n = this.palette.length;
//   }

//   get = (i) => this.palette[Math.floor(i) % this.n];

//   static hexToRgba = (hex, alpha) => {
//     var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//     return result
//       ? `rgba(${[parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)].join(
//         ", "
//       )}, ${alpha})`
//       : null;
//   };
// }
